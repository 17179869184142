import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"

const CommitteesPage = () => (
  <Layout>
    <SEO title="Committees" />
    <Banner title="Committees"></Banner>

    <section id="content">
        <div className="content-wrap">
        <div className="container clearfix">
        <div className="col-md-12 col-lg-8">
            <div className="row clearfix">
            <div className="heading-block" style={{paddingTop: '40px'}}>
                <h3>Advisory board
                </h3>
            </div>
            </div>
            <div className="row clearfix">
            <div className="mb-6">
                <p className="mb-1">Ministry of Health</p>
                <p className="mb-1">Director of Dr Soetomo General Academic Hospital</p>
                <p className="mb-1">Dean of Faculty of Medicine Universitas Airlangga</p>
                <p className="mb-1">Chairman Association of Tissue Bank Indonesia and Regenerative Medicine</p>
                <p className="mb-1">Head of Airlangga Stem Cell Center </p>
                <p className="mb-1">Chairman of Indonesian Stem Cell Association</p>
                <p className="mb-1">Chairman of Indonesian Association of Tissue Engineering and Cell Therapy</p>
                <p className="mb-1">Prof. Puruhito, MD., PhD</p>
                <p className="mb-1">Prof. M. Sjaifuddin Noer, MD, PhD</p>
                <p className="mb-1">Prof. Cita Rosita Prakoeswo, MD, PhD</p>
                <p className="mb-1">Prof. Fedik A. Rantam, PhD</p>
                <p className="mb-1">Prof. Dwikora Novembri Utomo, MD, PhD</p>
            </div>
            </div>
            <div className="row clearfix">
            <div className="mb-5">
                <h3>Treasury and Finance
                </h3>
                <ol className="ol-size ml-4 mb-3">
                <li>Sitti Rizaliyana MD</li>
                <li>Rossy Setiawati. MD, PhD</li>
                <li>Trio Rachmawati, M.Sc</li>
                <li>Endang Tris Handayani</li>
                <li>Fani Deapsari, M,Pharm</li>
                </ol>
            </div>
            </div>
            <div className="row clearfix">
            <div className="mb-5">
                <h3>Scientific Committee
                </h3>
                <ol className="ol-size ml-4 mb-3">
                <li>Ferdiansyah, MD, PhD</li>
                <li>Asra Al Fauzi, MD, PhD</li>
                <li>Damayanti Tinduh, MD, PhD</li>
                <li>Pramono Ari Wibowo, MD</li>
                <li>Pratiwi Soesilowati, BDS, DDS, MDSc, PhD</li>
                <li>Hendrik Setiabudi, BDS, DDS, PhD</li>
                <li>Arif Rahman, MD</li>
                <li>Yan Efrata Sembiring, MD</li>
                <li>Anrdianto, MD, PhD</li>
                <li>Martha Kurnia Kusumawardani, MD</li>
                <li>M. Hardian Basuki, MD</li>
                <li>Yuanita Safitri Dianti, MD</li>
                <li>Nining Dwi Suti Ismawati, PhD</li>
                <li>Jifaldi Afrian Maharaja Dinda Sedar, MD</li>
                <li>Steesy Benedicta, MD</li>
                <li>Tri Rahayu, Ssi</li>
                <li>Novialdi, S. Farm, Apt</li>
                </ol>
            </div>
            </div>
            <div className="row clearfix">
            <div className="mb-5">
                <h3>Organization
                </h3>
                <ol className="ol-size ml-4 mb-3">
                <li>Yulianto Listiawan, MD, PhD</li>
                <li>Tri Wahyu Martanto, MD</li>
                <li>Jefry Adrianus Muslim, MD</li>
                <li>Dra. Soeparti</li>
                <li>Dina Ayu Pratiwi, S.Si</li>
                <li>Evlyn Anggraini Santoso, ST</li>
                </ol>
            </div>
            </div>
            <div className="row clearfix">
            <div className="mb-5">
                <h3>Workshop
                </h3>
                <ol className="ol-size ml-4 mb-3">
                <li>Mouli Edward, MD</li>
                <li>Yunus Abdul Bari, MD</li>
                <li>Basril Abbas</li>
                <li>Trio Rachmawati, Dipl. TB, M.Si</li>
                <li>Annas Prasetyo Adi, M.Si</li>
                <li>Yulfaida Ermawati, Amd. Kep, Dipl.TB</li>
                <li>Helen Susilowati, M.Kes</li>
                </ol>
            </div>
            </div>
            <div className="row clearfix">
            <div className="mb-5">
                <h3>Seminar Programme
                </h3>
                <ol className="ol-size ml-4 mb-3">
                <li>Jefry Adrianus Muslim, MD</li>
                <li>Yuanita Safitri Dianti, MD</li>
                <li>Kukuh Dwiputra Hernugrahanto, MD</li>
                <li>Fani Deapsari, M,Pharm</li>
                </ol>
            </div>
            </div>
            <div className="row clearfix">
            <div className="mb-5">
                <h3>Publication, Documentation and Audio Visual
                </h3>
                <ol className="ol-size ml-4 mb-3">
                <li>Hizbillah Yazid, MD</li>
                <li>Tabita Prajasari, MD</li>
                <li>Ines Soepinarko, MARS</li>
                </ol>
            </div>
            </div>
            <div className="row clearfix">
            <div className="mb-3">
                <h3>Supporting Team
                </h3>
                <ol className="ol-size ml-4 mb-3">
                <li>Analisa Sawiji Putriyekti, S.KM</li>
                <li>Dewi Surya Lestiyani, S.Si</li>
                <li>Ika Sari Aprilia, S.KM</li>
                <li>Zahrotul Hasanah, S.Si</li>
                <li>Tri Rahayu, S.Si</li>
                <li>Rony Imam Wahyudi, SE</li>
                <li>Endang Tris Handayani</li>
                <li>Eddy Harianto</li>
                </ol>
            </div>
            </div>
        </div>
        </div>
    </div>
    </section>


  </Layout>
)

export default CommitteesPage
