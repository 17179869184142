import React from "react"
import cover from "./images/cover.png"

const Banner = props => {
  const bannerStyle = {
    backgroundImage: `url("${cover}")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 50%",
  }
  return (
    <section id="page-title" style={bannerStyle}>
      <div className="container clearfix">
        <h1>{props.title}</h1>
      </div>
    </section>
  )
}

export default Banner
